<template>
    <associations-component/>
</template>

<script>
    import AssociationsComponent from "@/components/admin/catalogs/AssociationsComponent";
    
    export default {
        name: "Associations",
        title: "Asociaciones | Turismo BC",
        components: {AssociationsComponent},
    }
</script>

<style scoped>

</style>